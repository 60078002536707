<template>
  <b-container class="mt-4">
    <b-row>
      <b-col cols="11">
        <h4>SFTP User Edit</h4>
      </b-col>
      <b-col>
        <b-button @click="updateUser" :disabled="loading || $v.$invalid" variant="primary" class="float-right">
          <feather type="save"></feather>
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="loading">
      <b-col cols="12">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>
    <b-row v-else class="mt-2">
      <b-col cols="12">
        <b-card>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Username</b>
            </b-col>
            <b-col cols="6">
              <b-form-input disabled v-model="user.username"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>First Name</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="additional_info.firstname" :state="!$v.additional_info.firstname.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Last Name</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="additional_info.lastname" :state="!$v.additional_info.lastname.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-if="server === 'external'">
            <b-col cols="2">
              <b>Company</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="additional_info.company" :state="!$v.additional_info.company.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-if="server === 'external'">
            <b-col cols="2">
              <b>Root Folder</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.filesystem.gcsconfig.key_prefix" :state="!$v.user.filesystem.gcsconfig.key_prefix.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <b>Active</b>
            </b-col>
            <b-col cols="6">
              <b-form-checkbox
                id="user-status"
                v-model="user.status" :state="!$v.user.status.$invalid"/>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-if="server === 'external'">
            <b-col cols="2">
              <b>Emails</b>
            </b-col>
            <b-col cols="6">
              <b-row v-for="(_, idx) in additional_info.emails" :key="idx" class="mb-2">
                <b-col cols="11">
                  <b-form-input v-model="additional_info.emails[idx]" :state="!$v.additional_info.emails.$each[idx].$invalid"/>
                </b-col>
                <b-col cols="1" class="pl-0">
                  <b-button @click="removeEmail(idx)"
                            variant="danger" size="md"><feather type="trash"></feather></b-button>
                </b-col>
              </b-row>
              <div class="mt-2 mb-2">
                <b-button @click="addNewEmail" variant="primary" size="md"><feather type="plus"></feather></b-button>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="server === 'external'">
            <b-col cols="2"/>
            <b-col cols="6">
              <b-row class="mt-2" v-if="$v.additional_info.emails.$invalid && additional_info.emails.length === 0">
                <b-col cols="12">
                  <p class="invalid-feedback" style="display: block">At least one email is required</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="d-flex align-items-center mt-1" v-else>
            <b-col cols="2">
              <b>Email</b>
            </b-col>
            <b-col cols="6">
              <b-form-input v-model="user.email" :state="!$v.user.email.$invalid"/>
            </b-col>
          </b-row>
          <b-row v-if="server === 'internal'" class="d-flex align-items-center mt-1">
            <b-col cols="2">
              <p class="card-text"><b>Groups</b></p>
            </b-col>
            <b-col cols="6">
              <multiselect :max="2" :multiple="true" v-model="groups" :options="options"/>
            </b-col>
          </b-row>
          <p class="card-text mt-2"><b>Public Keys:</b></p>
          <div class="ml-2 mb-3" v-if="publicKeys">
            <b-row v-for="(_, idx) in publicKeys" :key="idx">
              <b-col cols="7">
                <b-textarea rows="4" v-model="publicKeys[idx]"/>
              </b-col>
              <b-col cols="1">
                <b-button @click="removePublicKey(idx)" variant="danger" size="md"><feather type="trash"></feather></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="1">
                <b-button @click="addNewPublicKey" variant="primary" size="md"><feather type="plus"></feather></b-button>
              </b-col>
            </b-row>
          </div>
          <p class="card-text"><b>Permissions</b></p>
          <div class="ml-2">
            <b-row v-for="(permission, idx) in permissions" :key="idx" class="mt-1 d-flex align-items-center">
              <b-col cols="3">
                <b-form-group
                  :id="`label-permission-path-${idx}`"
                  label="Folder Path"
                  invalid-feedback="Ex: /test/simpsons/"
                  :label-for="`permission-path-${idx}`">
                  <b-form-input
                    :id="`permission-path-${idx}`"
                    v-model="permission.path"
                    :state="!$v.permissions.$each[idx].path.$invalid"
                    type="text"
                    placeholder="Enter filepath"
                    required/>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group
                  :id="`label-permission-role-${idx}`"
                  label="Role"
                  :label-for="`permission-role-${idx}`">
                  <b-form-select
                    :id="`permission-role-${idx}`"
                    :state="!$v.permissions.$each[idx].role.$invalid"
                    v-model="permission.role"
                    :options="roles"/>
                </b-form-group>
              </b-col>
              <b-col cols="1">
                <b-button @click="removePermission(idx)" variant="danger" size="md"><feather type="trash"/></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="1">
                <b-button @click="addNewPermission" variant="primary" size="md"><feather type="plus"/></b-button>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="$v.permissions.$invalid && permissions.length === 0">
              <b-col cols="12">
                <p class="invalid-feedback" style="display: block">At least one permission is required</p>
              </b-col>
            </b-row>
          </div>
          <hr>
          <b-row>
            <b-col>
              <h4>Actions</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button :disabled="lock_actions" @click="openModal('reset-password')" variant="warning" size="md" style="width: 230px">
                <feather type="trash"></feather>
                Reset Password
              </b-button>
              <b-button :disabled="lock_actions" @click="openModal('re-send-invitation')" variant="info" class="ml-2" style="width: 230px">
                <feather type="trash"></feather>
                Re-Send User Invitation
              </b-button>
              <b-button :disabled="lock_actions" @click="openModal('delete-user')" variant="danger" class="ml-2" style="width: 230px">
                <feather type="trash"></feather>
                Delete User
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="actions-modal">
      <template #modal-title>
        {{ modal.title }}
      </template>
      <div class="d-block text-left" style="white-space: pre-line">{{ modal.message }}</div>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" style="width: 80px;" variant="success" @click="acceptModalAction()">
          Confirm
        </b-button>
        <b-button size="md" style="width: 80px;" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { isNullOrEmpty } from '@/helpers';
import Multiselect from 'vue-multiselect';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { parseValidationsStr } from '@sword-health/input-validation';
import { mapGetters } from 'vuex';

export default {
  name: 'SFTPUserDetails',
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: false,
      server: null,
      username: null,
      user: null,
      additional_info: {
        firstname: null,
        lastname: null,
        company: null,
        emails: [],
      },
      modal: {
        title: null,
        message: null,
        action: null,
      },
      permissions: [],
      publicKeys: [],
      groups: [],
      options: [],
      roles: [
        { value: 'admin', text: 'Admin' },
        { value: 'write', text: 'Write' },
        { value: 'read', text: 'Read' },
        { value: 'deny', text: 'Deny' },
      ],
      lock_actions: false,
    };
  },
  validations() {
    return this.rules;
  },
  computed: {
    ...mapGetters({
      canAccessSftpUsersAndGroups: 'User/canAccessSftpUsersAndGroups',
    }),
    rules() {
      const cfg = {
        user: {
          status: { required },
        },
        additional_info: {
          firstname: { required },
          lastname: { required },
        },
        permissions: {
          $each: {
            path: {
              isUnique(value) {
                if (value === '') {
                  return true;
                }

                return this.permissions.filter(o => o.path === value).length <= 1;
              },
              ...parseValidationsStr({ required, validChars: /(^\/.*\/$|^\/$)/ }),
            },
            role: { required },
          },
          required,
          minLength: minLength(1),
        },
        publicKeys: {
          $each: {
            required,
            isUnique(value) {
              if (value === '') {
                return true;
              }

              return this.publicKeys.filter(o => o === value).length <= 1;
            },
          },
        },
      };

      if (this.server === 'external') {
        cfg.user.filesystem = {
          gcsconfig: {
            key_prefix: {
              ...parseValidationsStr({ required, validChars: /^[^\\/].*\/$/ }),
            },
          },
        };

        cfg.additional_info.company = { required };

        cfg.additional_info.emails = {
          $each: {
            required,
            email,
            isUnique(value) {
              if (value === '') {
                return true;
              }

              return this.additional_info.emails.filter(o => o === value).length <= 1;
            },
          },
          required,
          minLength: minLength(1),
        };
      } else {
        cfg.user.email = {
          required,
          email,
        };
      }

      return cfg;
    },
  },
  async beforeMount() {
    if (!this.canAccessSftpUsersAndGroups) {
      this.$router.push('/not-found');
      return;
    }

    this.server = this.$route.params.server;
    this.username = this.$route.params.username;
    try {
      this.loading = true;
      await this.getGroups();
      await this.getUser();
    } catch (err) {
      this.$noty.error(`Failed to fetch user details: ${err.message}`);
    }
  },
  methods: {
    async getGroups() {
      const { data } = await this.$store.dispatch('Sftp/Groups/getGroups', { server: this.server });
      data.forEach(group => this.options.push(group.name));
    },
    async getUser() {
      try {
        this.loading = true;

        const { data } = await this.$store.dispatch('Sftp/User/getUser', {
          server: this.server,
          username: this.username,
        });

        this.user = data;
        this.user.status = this.user.status === 1;
        this.publicKeys = isNullOrEmpty(data.public_keys) ? [] : data.public_keys;
        this.permissions = Object.entries(data.permissions).map(([ path, role ]) => ({ path, role }));

        if (data.groups) {
          this.groups = data.groups.map(group => group.name);
        }

        this.parseAdditionalInfo();
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async updateUser() {
      try {
        this.loading = true;
        const payload = {
          server: this.server,
          username: this.username,
          body: {
            username: this.username,
            email: this.user.email,
            additional_info: JSON.stringify({
              firstname: this.additional_info.firstname,
              lastname: this.additional_info.lastname,
              company: this.additional_info.company,
              email: this.user.email,
              emails: this.additional_info.emails,
            }),
            public_keys: this.publicKeys,
            permissions: this.permissions.reduce((acc, { path, role }) => {
              acc[path] = role;
              return acc;
            }, {}),
            status: this.user.status ? 1 : 0,
            filesystem: {
              key_prefix: this.user.filesystem.gcsconfig.key_prefix,
            },
          },
        };

        if (this.server === 'internal') {
          payload.body.groups = [];
          let i = 1;
          this.groups.forEach(group => {
            payload.body.groups.push({ name: group, type: i });
            i++;
          });
        }

        await this.$store.dispatch('Sftp/User/updateUser', payload);
        this.$noty.success('User updated successfully');
        this.$router.push(`/sftp/users/${this.server}/${this.username}`);
        return;
      } catch (err) {
        console.error(err);
        this.$noty.error(`Failed to update user: ${err?.response?.data?.message || err.message}`);
      } finally {
        this.loading = false;
      }
    },
    addNewPermission() {
      this.permissions.push({ path: '', role: '' });
    },
    removePermission(idx) {
      this.permissions.splice(idx, 1);
    },
    addNewPublicKey() {
      this.publicKeys.push('');
    },
    removePublicKey(idx) {
      this.publicKeys.splice(idx, 1);
    },
    addNewEmail() {
      this.additional_info.emails.push('');
    },
    removeEmail(idx) {
      this.additional_info.emails.splice(idx, 1);
    },
    parseAdditionalInfo() {
      if (!isNullOrEmpty(this.user.additional_info)) {
        const info = JSON.parse(this.user.additional_info);

        if (!isNullOrEmpty(info.firstname)) {
          this.additional_info.firstname = info.firstname;
        }

        if (!isNullOrEmpty(info.lastname)) {
          this.additional_info.lastname = info.lastname;
        }

        if (!isNullOrEmpty(info.company)) {
          this.additional_info.company = info.company;
        }

        if (!isNullOrEmpty(info.emails)) {
          this.additional_info.emails = info.emails;
        }
      }
    },
    openModal(action) {
      switch (action) {
        case 'reset-password':
          this.modal.title = 'Reset Password Confirmation';
          this.modal.message = 'Are you sure you want to reset this user password?\n'
            + 'This action cannot be undone.';
          break;
        case 're-send-invitation':
          this.modal.title = 'Re-Send User Invitation Confirmation';
          this.modal.message = 'Are you sure you want to re-send the user invitation?';
          break;
        case 'delete-user':
          this.modal.title = 'Delete User Confirmation';
          this.modal.message = 'Are you sure you want to delete this user?\n'
            + 'This action cannot be undone.';
          break;
        default:
          console.error(`Unknown action ${action}`);
      }

      this.modal.action = action;
      this.$refs['actions-modal'].show();
    },
    acceptModalAction() {
      switch (this.modal.action) {
        case 'reset-password':
          this.resetPassword();
          break;
        case 're-send-invitation':
          this.reSendInvitation();
          break;
        case 'delete-user':
          this.deleteUser();
          break;
        default:
          console.error(`Unknown action ${this.modal.action}`);
      }
      this.$refs['actions-modal'].hide();
    },
    async resetPassword() {
      try {
        this.lock_actions = true;
        await this.$store.dispatch('Sftp/User/resetUserPassword', {
          server: this.server,
          username: this.username,
        });
        this.$noty.success('Password reset successfully');
      } catch (err) {
        console.error(err);
        this.$noty.error(`Failed to reset password: ${err?.response?.data?.message || err.message}`);
      } finally {
        this.lock_actions = false;
      }
    },
    async reSendInvitation() {
      this.$noty.success('Not implemented yet');
    },
    async deleteUser() {
      try {
        this.lock_actions = true;
        await this.$store.dispatch('Sftp/User/deleteUser', {
          server: this.server,
          username: this.username,
        });
        this.$noty.success('User deleted successfully');
        this.$router.push(`/sftp/users/${this.server}`);
      } catch (err) {
        console.error(err);
        this.$noty.error(`Failed to delete user: ${err?.response?.data?.message || err.message}`);
      } finally {
        this.lock_actions = false;
      }
    },
  },
};
</script>
